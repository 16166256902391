import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const SecretsOfCreatingDiagnosticContentAndForViralTwitterCampaigns = ({
    location
  }) => {
  const title =
    "【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？"
          pagedesc="ユーザーが質問に回答すると診断結果が表示される「診断コンテンツ」。商品レコメンド・Webプロモーション・顧客獲得につながるなど、メリットの多いコンテンツです。本記事では、診断コンテンツの作り方やTwitterキャンペーンへの展開方法などについて説明します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns-1.jpg"
          pagepath="/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年5月21日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="えんぴつを噛んでいる女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns-1.jpg"
                className="w-full"
              />
            </figure>
            <p>
              「さまざまな方法を使って自社の商品やサービスを宣伝しているけれど、なかなかクリックしてもらえない…」そんなお悩みを解決に導くのが、SNSキャンペーンと相性の良い「診断コンテンツ」です。
            </p>
            <p>
              診断コンテンツとは、用意した設問にユーザーが回答し、その診断結果を表示できるコンテンツのこと。ユーザーが手軽に参加しやすいことから、参加率が高いほか、商品レコメンド・Webプロモーション・顧客獲得につながるなど、メリットが多い施策です。カスタマイズ性が高く、コンテンツの内容やデザインも自由なので、目的に応じて柔軟に設計できます。
            </p>
            <br />
            <p>
              本記事では、診断コンテンツの作り方やTwitterキャンペーンへの展開方法などを詳しく解説します。診断コンテンツを導入するメリットや、目的ごとの活用方法についても触れるので、キャンペーンの一環として診断コンテンツを検討している方はぜひご覧ください。
            </p>
            <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>診断コンテンツを作成したいけど、どこから始めればいいかわからない方</li>
                <li>診断コンテンツとハッシュタグキャンペーンで話題を作りたいマーケティング担当者</li>
                <li>各ユーザーに合ったおすすめ商品を紹介して、購買につなげたい企業</li>
              </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1. 診断コンテンツとは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    2. 診断コンテンツ作成のメリット
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    3. 目的ごとの診断コンテンツの活用法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        活用法① 商品レコメンド
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        活用法② Webプロモーション
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        活用法③ 顧客獲得
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    4. 診断コンテンツ作成時のよくある失敗とその解決策
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        失敗例① 時間やリソースをかけすぎる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        失敗例② 不必要な設問を用意してしまう
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        失敗例③ 診断コンテンツを作成して満足してしまう
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    5. Twitterキャンペーンに展開する診断コンテンツの作り方とは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ＃（ハッシュタグ）を使って、診断結果を拡散！
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        診断結果を自動で拡散できるようにする診断コンテンツの作り方
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        多くのユーザーにシェアしてもらうには？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        診断コンテンツ作成〜SNS拡散はPARKLoTにおまかせ
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">1. 診断コンテンツとは？</h2>
                <img
                  placeholder="tracedSVG"
                  alt="診断コンテンツのイメージ"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns-2.png"
                  className="mb-4"
                />
              <p>
                「診断コンテンツ」とは、ユーザーが質問に回答し、回答の内容に応じた診断結果が表示されるユーザー参加型のコンテンツのこと。SNSや特設サイト上で展開できます。Twitterキャンペーンと併用することもでき、汎用性に優れたコンテンツです。
              </p>
              <p>
                コンテンツの内容は、性格診断、キャラクター診断、○○検定、おすすめメニュー診断など、さまざま。エンタメ性が強く気軽に参加できるので、多くのユーザー参加を見込めます。特に、ユーザーの興味関心に沿ったコンテンツ内容であればあるほど、参加率が高くなる傾向にあります。
              </p>
            </div>
            <div>
              <h2 id="id2">2. 診断コンテンツ作成のメリット</h2>
              <p>
                診断コンテンツはカスタマイズ性が高く、幅広いコンテンツ作成が可能なので、得られるメリットも多岐にわたります。
              </p>
              <ul>
                <li>
                  興味喚起に優れている
                  <p>
                    ターゲットユーザーの興味・関心を惹くようなコンテンツを作成することで、興味喚起ができます。「自分のことを知りたい」「診断してほしい」というユーザーの気持ちをフックに、自社のコンテンツに対して興味を持ってもらえます。
                  </p>
                </li>
                <li>
                  PVの向上やWebサイトへのトラフィックの増加
                  <p>
                    診断コンテンツがシェアされることで、より多くのユーザーの目に留まる確率が高まります。その結果、PV数やトラフィックの増加も期待できます。
                  </p>
                </li>
                <li>
                  商品レコメンドが可能
                  <p>
                    自社の商品やサービスに関連する診断コンテンツが作成できるので、ユーザーに対して診断結果に合った商品をおすすめできます。ユーザーの嗜好に近い提案になるため、興味関心が高まりやすく、その後のプロモーションや購買につながります。
                  </p>
                </li>
                <li>
                  顧客獲得・認知拡大・ブランディングに効果的
                  <p>
                    診断コンテンツに診断結果をシェアできる機能をつけることで、SNSキャンペーンと連動させながら効果的な情報拡散が可能になります。SNSでの拡散により、さらに多くの参加者を集めることでしょう。
                  </p>
                </li>
                <li>
                  ユーザーのデータ収集も可能
                  <p>
                    ユーザーのデータを集めるためにアンケート調査などを行うこともあると思いますが、回答率が低かったり、インセンティブを別途用意する必要があったり、企業側にとってはさまざまな課題が発生することも。そんなときに診断コンテンツを利用すれば、ユーザーの回答データを低コストで効率的に集めることが可能になります。
                  </p>
                </li>
              </ul>
              <br />
              <p>
                以上、診断コンテンツにはさまざまなメリットがありますが、まずはユーザーの興味を惹き、参加してもらえるような診断コンテンツを作ることが重要です。上記のメリットも踏まえたうえで、診断コンテンツの導入をぜひ検討してみてください。
              </p>
            </div>
            <div>
              <h2 id="id3">3. 目的ごとの診断コンテンツの活用法</h2>
              <p>
                診断コンテンツは汎用性が高いコンテンツですが、効果的に運用するには目的に合った使い方をするのが重要です。代表例を3つ挙げるので、ぜひ参考にしてみてください。
              </p>
              <h3 id="id4">活用法① 商品レコメンド</h3>
              <p>
                ある特定の製品やサービスをピンポイントで訴求したい場合は、診断コンテンツ内で商品レコメンドするのがおすすめです。商品レコメンドとは、ユーザーに適した商品などを直接おすすめできる機能のこと。どの商品をおすすめするかなどは、自由にカスタマイズできます。
              </p>
              <p>
                診断結果に基づき、ユーザーに合ったおすすめ商品をピンポイントで訴求できるので、購入につながりやすいのが特徴です。診断結果ページに商品購入リンクをはり、ユーザーのスムーズな遷移を促すことができます。
              </p>
              <h3 id="id5">活用法② Webプロモーション</h3>
              <p>
                自社の製品やサービスをユーザーに認知・購買してもらうのが目的なら、Webプロモーションの一環として診断コンテンツを導入しましょう。例えば、診断コンテンツ内に広告を掲載したり、SNSキャンペーンを併用したりするなどの方法が挙げられます。
              </p>
              <p>
                診断コンテンツは、「診断結果を周りの人にも知ってもらいたい」というユーザーの欲求を刺激し、シェアにつながりやすいといわれています。効率よく拡散を行い、プロモーションを行うのにうってつけの施策です。
              </p>
              <h3 id="id6">活用法③ 顧客獲得</h3>
              <p>
                登録会員の増加、企業アカウントのフォロー数増加など、新規顧客の獲得を目的とする場合は、顧客獲得につながる診断コンテンツを作成するのがおすすめ。キャンペーンを併用し、応募条件を「企業アカウントのフォロー」「サイトの会員登録」にするなどが有効です。
              </p>
              <p>
                診断コンテンツは、診断を通して自分を客観的に把握できることはもちろん、診断結果の画像・テキストのなかで商品・サービスを効果的に訴求できるのが特徴。通常の広告よりもセールス感を抑えられるので、ユーザーから警戒心をもたれることなく顧客獲得を実現できます。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？～
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id7">4. 診断コンテンツ作成時のよくある失敗とその解決策</h2>
              <p>
                次は、よくある失敗例とその解決方法について紹介します。診断コンテンツは実施のメリットが多いものの、その特性を活かしきれないと、効果が十分に発揮されないこともあります。
              </p>
              <h3 id="id8">失敗例① 時間やリソースをかけすぎる</h3>
              <p>
                よくある失敗例のひとつが「リソースをかけすぎてしまう」ことです。デザインやカスタマイズ性に凝りすぎてしまうと、コストやリソースがかかりいつまでたってもコンテンツが完成しません。
              </p>
              <p>
                ケースバイケースですが、自社で設定した目的・KPIを達成できるのであれば、診断コンテンツの開発にそこまで手間やお金をかける必要はありません。例えば、あらかじめ用意されたフォーマットを使ったコンテンツ作成であれば、リソースを抑えて、診断コンテンツを作成することもできます。
              </p>
              <h3 id="id9">失敗例② 不必要な設問を用意してしまう</h3>
              <p>
                診断コンテンツの作り方において、「不必要な設問を入れてしまう」のはNG。たしかに、設問を増やせば、それだけデータも集まるので運営側にとってはメリットが多いでしょう。しかし、ユーザー側にとっては負担が増えるので、想定以上のユーザーが離脱するリスクも考えられます。
              </p>
              <p>
                設問を考えるときには、診断コンテンツの内容に沿った設問のみを、必要な数だけ用意するようにしましょう。
              </p>
              <h3 id="id10">失敗例③ 診断コンテンツを作成して満足してしまう</h3>
              <p>
                診断コンテンツを作成しただけで、満足してしまってはいけません。診断コンテンツは、診断後に購買やプロモーション、拡散などにつながるアプローチを行うことが重要です。あらかじめ目的を設定し、診断コンテンツの実施からアプローチまで一連の流れを設計するようにしましょう。
              </p>
              <br />
              <p>
                診断コンテンツの作成だけでなく、企画やプロモーションやキャンペーンなどについても効果的に行いたい場合は、SNSマーケティングに強みを持つ会社に依頼をするのもおすすめ。診断コンテンツの効果を高められるのはもちろん、社内のコストを大幅に削減できます。
              </p>
            </div>
            <div>
              <h2 id="id11">5. Twitterキャンペーンに展開する診断コンテンツの作り方とは？</h2>
              <img
                placeholder="tracedSVG"
                alt="診断コンテンツの作り方のイメージ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns-3.png"
                className="mb-4"
              />
              <p>
                診断コンテンツの導入を検討している企業・店舗のなかには、Twitterキャンペーンとの併用を考えている場合もあるのではないでしょうか。ここでは、「Twitterキャンペーンと連携させる場合の診断コンテンツの作り方」について、具体的な全体設計と作り方を解説していきます。
              </p>
              <h3 id="id12">＃（ハッシュタグ）を使って、診断結果を拡散！</h3>
              <p>
                診断コンテンツをTwitterキャンペーンにつなげる方法はいくつか存在します。そのなかでも広く活用されているのが、＃（ハッシュダグ）をつけて診断結果をTwitterでシェアしてもらう方法。プレゼントキャンペーンとの相性がよく、効率的に拡散できます。ユーザーが結果をシェアすると、その投稿をもってキャンペーン応募となります。魅力的な景品を用意することで、診断を受けたあとのシェアにつながり、さらにその投稿を見た他のユーザーも診断に参加してくれる効果が期待できます。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </p>
              </div>              
              <h3 id="id13">診断結果を自動で拡散できるようにする診断コンテンツの作り方</h3>
              <p>
                診断結果をシェアするには、診断結果の表示画面にTwitterへ簡単に遷移できるボタンを配置するのが一般的。遷移後は自動的に指定のハッシュタグがついた文章が生成されるような設計を行えば、ユーザーも手軽に診断結果をシェアできるようになります。
              </p>
              <p>
                また、ユーザーからの投稿を効率的にリスト化するシステム（抽選ツール）を利用するもの重要です。なぜなら、プレゼントの当選者決定をより簡単に行えるからです。そのほか、診断結果の画面上でハッシュタグ付きのシェア（Twitterに投稿するかどうか）に関する許諾説明を表示することもできるので、ユーザーとのトラブルを未然に防ぐことができます。
              </p>
              <h3 id="id14">多くのユーザーにシェアしてもらうには？</h3>
              <p>
                診断コンテンツは「診断結果を周囲に知らせたい」というユーザー心理を刺激するため、拡散効果が高いのがメリットです。シェアされた投稿を目にした別のユーザーが、自分も診断を行い結果を投稿することで、広く拡散されていきます。
              </p>
              <p>
                そのため、コンテンツは幅広いユーザーの興味関心を惹く内容であることが重要です。そのときのトレンドや社会状況によっても異なるので、市場分析などを行い、「やってみよう」と多くの人が思えるコンテンツを作成するようにしましょう。
              </p>
              <h3 id="id15">診断コンテンツ作成〜SNS拡散はPARKLoTにおまかせ</h3>
              <img
                placeholder="tracedSVG"
                alt="最短１ヶ月で準備が完了します"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns-4.png"
                className="mb-4"
              />
              <p>
                ここまで診断コンテンツについて解説しましたが、実際に作成しようとすると、どのように診断コンテンツを作成すればいいのか分からないこともあるでしょう。その場合は、専門の企業に相談するのも一つの手です。
              </p>
              <p>
                PARKLoTでは、完全カスタマイズで自社オリジナルの診断コンテンツを作成できるのはもちろん、フォーマットを使ってコストを削減した作成もできるので、予算に合った設計が可能です。
              </p>
              <p>
                診断コンテンツの実装まで最短1ヶ月なので、スピーディーに診断コンテンツを試してみたい企業にもおすすめです。
              </p>
              <p>
                また、ビジュアル面も含め、拡散されやすい診断コンテンツの企画・設計のサポートも可能。さらに、診断コンテンツと併用するTwitterキャンペーンも合わせて依頼できます。
              </p>
              <p>
                診断コンテンツを効果的に活用したい場合はぜひPARKLoTまでお問い合わせください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/rise-engagement-rate-with-retweet-or-like-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    驚きのエンゲージメント率！ リツイートいいねキャンペーンの魅力を徹底解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/campaign-for-food-and-restaurants"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【食品・飲食店特化】売上アップ！集客キャンペーン事例をわかりやすく解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSプロモーションを成功させるには？ 成功事例から学べる4つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-reduce-cost-of-sns-operations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【Twitter企業アカウント】うまく運用代行を活用して業務コストを削減する方法とは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-message"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【当選通知テンプレを公開】次につながるTwitterキャンペーンのメッセージとは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-choose-sns-marketing-tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSマーケティングツールが必要な理由とは？分析ツールの選び方のキホンを初心者向けに紹介！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default SecretsOfCreatingDiagnosticContentAndForViralTwitterCampaigns
